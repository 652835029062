
// 编辑、新增角色
<template>
  <base-dialog
    :visible.sync="visible"
    @submit="submit"
    :title="title"
    width="800px"
    class="edit_role_dialog"
  >
    <base-form
      :componentList="componentList"
      :formAttrs="{
        model: formData,
        labelWidth: '140px',
      }"
      class="formStyle"
      :showBtns="false"
      ref="formData"
    >
    </base-form
  ></base-dialog>
</template>
<script>
import baseForm from '@/components/common/base-form//base-form.vue'
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import { createRole, updateRole } from '@/api/sys/role.js'
import { addFormConfig } from './utils/edit-config'

export default {
  components: { baseForm, baseDialog },
  props: {
    title: {
      type: String,
      default: '新增'
    },
    model: {}
  },
  data () {
    return {
      visible: false,
      formData: {},
      preData: {}
    }
  },
  computed: {
    componentList () {
      return addFormConfig(this)
    }
  },
  methods: {
    async initData (row) {
      if (this.model === 'E') {
        this.preData = { ...row }
        this.formData = row
      } else {
        this.formData = Object.create(null)
      }
    },
    validate () {
      return this.$refs.formData.validate()
    },
    submit: async function () {
      try {
        await this.validate()
        if (this.model === 'E') {
          this.editor()
        } else if (this.model === 'C') {
          this.create()
        }
      } catch (error) {
        console.log(error, 'error')
      }
    },
    // 打开弹框
    open: function (row) {
      this.visible = true
      this.registerClear()
      this.initData(row)
    },
    // 新增角色
    create: function () {
      createRole(this.formData)
        .then((res) => {
          this.success('新增角色成功')
          this.visible = false
          this.$emit('refresh')
        })
        .catch(() => {
          this.error('新增角色失败')
        })
    },
    // 编辑角色
    editor: function () {
      updateRole(this.formData.roleId, this.formData)
        .then((res) => {
          this.success('保存成功')
          this.visible = false
          this.$emit('refresh')
        })
        .catch(() => {
          this.error('保存失败')
        })
    },
    /**
     * 关闭时处理重置数据逻辑
     */
    registerClear () {
      const listen = this.$watch('visible', function watcher () {
        listen()
        this.$nextTick(() => {
          this.formData = Object.create(null)
          this.$nextTick(() => {
            this.$refs.formData.clearValidate()
          })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped src="./role-management.scss">
</style>
