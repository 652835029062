var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"role-manage"},[_c('base-from',{staticClass:"formStyle",attrs:{"componentList":_vm.componentList,"formAttrs":{
      model: _vm.queryParas,
      labelWidth: '90px',
    }},on:{"handleFilter":_vm.handleFilter,"clearParams":_vm.clearParams}}),_c('div',{staticClass:"main-page-table__header"},[_c('base-button',{attrs:{"label":"新增","icon":"el-icon-plus"},on:{"click":_vm.handleCreate}})],1),_c('base-table',{ref:"tableData",staticClass:"main-page-table with-add",attrs:{"pageKeys":['pageNumber', 'pageSize'],"columns":_vm.columns,"showPage":true,"api":_vm.roleApi,"getApi":"getRolePages","loadCount":_vm.loadCount,"queryParas":_vm.queryParas,"tableAttrs":{
      stripe: true,
    },"dataSource":_vm.tableData,"webPage":false},on:{"update:dataSource":function($event){_vm.tableData=$event},"update:data-source":function($event){_vm.tableData=$event}},scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var row = ref.row;
return [_c('icon-button',{attrs:{"content":"编辑","icon":"iconfont iconbianji3"},on:{"click":function($event){return _vm.handleEditorRole(row)}}}),_c('icon-button',{attrs:{"content":"授权","icon":"iconfont iconshouquan"},on:{"click":function($event){return _vm.handleRoleAuth(row)}}}),_c('icon-button',{directives:[{name:"perm",rawName:"v-perm",value:('role_assign_user_btn'),expression:"'role_assign_user_btn'"}],attrs:{"content":"分配用户","icon":"iconfont iconfenpeiyonghu"},on:{"click":function($event){return _vm.handleAssignUser(row)}}}),_c('icon-button',{attrs:{"content":"删除","icon":"iconfont iconshanchu1"},on:{"click":function($event){return _vm.delRole(row)}}})]}}])}),_c('role-management-edit',{ref:"roleEditor",attrs:{"title":_vm.curTitle,"model":_vm.model},on:{"refresh":_vm.handleFilter}}),_c('roles-management-assign',{ref:"assignUser"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }