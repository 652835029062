<template>
  <div class="role-manage">
    <base-from
      :componentList="componentList"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
    >
    </base-from>
    <div class="main-page-table__header">
      <base-button
        label="新增"
        @click="handleCreate"
        icon="el-icon-plus"
      ></base-button>
    </div>
    <base-table
      class="main-page-table with-add"
      :pageKeys="['pageNumber', 'pageSize']"
      :columns="columns"
      :showPage="true"
      :api="roleApi"
      getApi="getRolePages"
      :loadCount="loadCount"
      :queryParas="queryParas"
      :tableAttrs="{
        stripe: true,
      }"
      :dataSource.sync="tableData"
      ref="tableData"
      :webPage="false"
    >
      <template slot="action" slot-scope="{ row }">
        <icon-button
          @click="handleEditorRole(row)"
          content="编辑"
          icon="iconfont iconbianji3"
        ></icon-button>
        <icon-button
          @click="handleRoleAuth(row)"
          content="授权"
          icon="iconfont iconshouquan"
        ></icon-button>
        <icon-button
          @click="handleAssignUser(row)"
          content="分配用户"
          icon="iconfont iconfenpeiyonghu"
          v-perm="'role_assign_user_btn'"
        ></icon-button>
        <icon-button
          @click="delRole(row)"
          content="删除"
          icon="iconfont iconshanchu1"
        >
        </icon-button>
      </template>
    </base-table>
    <!-- 新增/编辑 用户 -->
    <role-management-edit
      ref="roleEditor"
      @refresh="handleFilter"
      :title="curTitle"
      :model="model"
    ></role-management-edit>
    <!-- 分配用户穿梭框 -->
    <roles-management-assign ref="assignUser"></roles-management-assign>
  </div>
</template>
<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseFrom from '@/components/common/base-form//base-form.vue'
import { dataForm, dataTable } from './utils/list-config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import rolesManagementAssign from './role-management-assign.vue'
import * as roleApi from '@/api/sys/role'
import { getDictIdMaps } from '@/filters/fromDict'
import roleManagementEdit from './role-management-edit.vue'

export default {
  name: 'rolesManagement',
  components: {
    BaseFrom,
    rolesManagementAssign,
    BaseTable,
    roleManagementEdit,
    IconButton,
    BaseButton
  },
  data () {
    return {
      loadCount: 0,
      tableData: [],
      queryParas: {
        pageSize: 10,
        pageNumber: 1,
        delStatus: '0'
      },
      // 分配用户数据
      model: ''
    }
  },
  mounted () {
    this.handleFilter()
  },
  methods: {
    // 新增角色
    handleCreate () {
      this.model = 'C'
      this.$nextTick(() => {
        this.$refs.roleEditor.open()
      })
    },
    // 分配用户
    handleAssignUser (row) {
      this.$refs.assignUser.open({ ...row })
    },
    handleFilter () {
      this.queryParas.pageNumber = 1
      this.loadCount++
    },
    clearParams () {
      this.queryParas = this.$options.data().queryParas
    },
    // 编辑角色
    handleEditorRole (row) {
      this.model = 'E'
      this.$nextTick(() => {
        this.$refs.roleEditor.open({ ...row })
      })
    },
    async checkRoleHasUsers () {
      await roleApi.getUserListByRoleId()
    },
    // 删除角色
    async delRole (row) {
      const { roleId } = row

      try {
        const { data } = await roleApi.getUserListByRoleId(roleId)
        if (data?.length) {
          return this.warning('该角色下存在用户，不能删除')
        }
      } catch (error) {
        console.log(error)
      }
      this.$confirm('注意！角色删除后无法恢复，是否继续?', '警告')
        .then(() => {
          roleApi.delRole({ roleId: roleId, delStatus: 1 }).then((res) => {
            if (res.data) {
              this.$message.success('删除成功')
            } else {
              this.$message.error('删除失败')
            }
            this.handleFilter()
          })
        })
        .catch(() => {})
    },
    handleRoleAuth (row) {
      const { roleId, roleName } = row
      this.$router.push({
        path: '/basicDataManagement/rolesManagment/jurisdiction',
        query: { roleId, roleName }
      })
    }
  },
  computed: {
    roleApi () {
      return roleApi
    },
    userTypeMap () {
      return getDictIdMaps('USER_TYPE')
    },
    columns () {
      return dataTable(this)
    },
    componentList () {
      return dataForm(this)
    },
    // 标题
    curTitle () {
      return this.model === 'E' ? '编辑角色' : '新增角色'
    }
  }
}
</script>

<style scoped lang="scss" src="./role-management.scss">
</style>
