<template>
  <base-dialog
    :visible.sync="visible"
    @submit="submit"
    :title="title"
    width="800px"
    class="assignDialog"
  >
    <!-- 机构选择 -->
    <el-row class="org-select">
      <span>机构名称：</span>
      <base-select
        v-model="organization"
        style="width: 200px"
        :clearable="true"
        :disabled="orgDisabled"
        :options="orgOptions"
        :selectedField="orgSelectionConfigs"
      ></base-select>
    </el-row>
    <div class="organization_transfer">
      <el-transfer
        filterable
        :titles="['可分配用户', '已分配用户']"
        filter-placeholder="请输入"
        v-model="checkdUsers"
        :data="currentRoles"
        :props="transProps"
        @change="handleSelectedChange"
        ref="Transfer"
      >
      </el-transfer>
    </div>
  </base-dialog>
</template>
<script>
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import {
  getTenantUsersExclude,
  getUserListByRoleId,
  updateUsersTRoles
} from '@/api/sys/role'
import { debounce } from '@/utils/dom-util'
import { getOrgConfigsByOrgType } from '@/api/sys/user'
import { filterByKey } from './utils/edit-config'
export default {
  components: { baseDialog, BaseSelect },
  props: {
    title: {
      type: String,
      default: '分配用户'
    },
    model: {}
  },
  data () {
    return {
      organization: '',
      watcher: null,
      visible: false,
      currentRoleId: '',
      currentRoleType: '',
      transProps: {
        key: 'userId',
        label: 'nickName'
      },
      orgDisabled: false,
      orgOptions: [],
      orgSelectionConfigs: ['keyId', 'enterpriseName'],
      allUsers: [],
      checkdUsers: [],
      initCheckeds: [],
      selectedRoles: []
    }
  },
  computed: {
    currentRoles () {
      return filterByKey(
        [...this.selectedRoles, ...this.allUsers, ...this.initCheckeds],
        'userId'
      )
    }
  },
  watch: {
    organization (val) {
      this.getOptionsByOrgType(val)
    }
  },
  methods: {
    handleSelectedChange (current, derection, change) {
      if (derection === 'right') {
        // 左到右 添加selectedRoles
        const changes = this.currentRoles.filter((it) =>
          change.includes(it.userId)
        )
        this.selectedRoles.push(...changes)
      } else {
        this.selectedRoles = this.selectedRoles.filter(
          (it) => !change.includes(it.userId)
        )
      }
    },
    renderContent (h, option) {
      return h(
        'span',
        { domProps: { title: option.nickName } },
        option.nickName
      )
    },
    async initData (row) {
      this.currentRoleId = row.roleId
      this.currentRoleType = row.orgType
      // 稳城一方下拉禁用
      this.orgDisabled = row.orgType === '20'
      try {
        // 机构名称配置项获取
        if (!this.orgDisabled) {
          const { data = [] } = await getOrgConfigsByOrgType({
            orgType: row.orgType
          })
          this.orgOptions = data
        }

        this.getOptionsByOrgType()
        this.registerChildWatch()
      } catch (error) {
        console.error('机构配置加载失败~')
        this.orgOptions = []
      }
    },
    // 根据所选的机构进行人员筛选
    async getOptionsByOrgType (orgId) {
      this.allUsers = []
      this.checkdUsers = []
      this.initCheckeds = []
      this.selectedRoles = []

      const [{ data: users }, { data: checkUsers = [] }] = await Promise.all([
        //  获取所有用户
        getTenantUsersExclude({
          roleKid: this.currentRoleId,
          orgType: this.currentRoleType,
          orgId,
          tenantKid: this.$store.state.user.tenantId
        }),
        // 获取当前角色下的用户列表
        getUserListByRoleId(this.currentRoleId, {
          orgType: this.currentRoleType,
          orgId
        })
      ])

      this.checkdUsers = checkUsers.map((it) => it.userId)
      this.allUsers = users
      this.initCheckeds = checkUsers
    },

    registerChildWatch () {
      const tick = debounce(this.getAllRole, 200)
      this.$nextTick(() => {
        this.watcher = this.$refs.Transfer.$refs.leftPanel.$watch(
          'query',
          (val) => {
            tick(val)
          }
        )
      })
    },

    async getAllRole (val) {
      const { data: users } = await getTenantUsersExclude({
        roleKid: this.currentRoleId,
        tenantKid: this.$store.state.user.tenantId,
        search: val
      })
      this.allUsers = users
    },
    validate () {
      return this.$refs.fromdata.validate()
    },
    submit: async function () {
      try {
        await updateUsersTRoles({
          add: true,
          roleId: this.currentRoleId,
          userIds: this.checkdUsers
        })
        this.success('保存成功')
        this.visible = false
        this.$emit('refresh')
      } catch (error) {
        console.error(error)
      }
      console.log(this.checkdUsers)
    },
    // 打开弹框
    open: function (row) {
      this.visible = true
      this.registerClear()
      this.initData(row)
    },
    /**
     * 关闭时处理重置数据逻辑
     */
    registerClear () {
      const listen = this.$watch('visible', function watcher () {
        listen()
        if (typeof this.watcher === 'function') {
          this.watcher()
        }
        this.allUsers = []
        this.checkdUsers = []
        this.initCheckeds = []
        this.selectedRoles = []
        this.organization = ''
        // 清楚搜索内容
        this.$refs.Transfer.clearQuery('left')
        this.$refs.Transfer.clearQuery('right')
        // this.$nextTick(() => {
        //   this.fromData = Object.create(null)
        //   this.$nextTick(() => {
        //     this.$refs.fromdata.clearValidate()
        //   })
        // })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.assignDialog {
  /deep/ .el-dialog {
    margin-top: 12vh !important;
  }
  /deep/ .el-dialog__body {
    height: 510px;
    overflow-x: hidden;
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
  }
}
.org-select {
  padding: 0 20px;
  span {
    display: block;
    font-size: 14px;
    margin-bottom: 7px;
    margin-right: 10px;
    font-weight: 400;
  }
}
.organization_transfer {
  width: 100%;
  height: 100%;
  /deep/ .el-transfer {
    margin-top: 20px;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  /deep/ .el-transfer-panel {
    width: 37%;
    height: 100%;
    text-align: left;
    .el-transfer-panel__body {
      height: calc(100% - 40px);
      .el-transfer-panel__filter {
        width: 90%;
        margin: 8px 15px;
      }
      .el-transfer-panel__list.is-filterable {
        height: calc(100% - 40px);
        overflow: auto;
      }
    }
  }
}
</style>
