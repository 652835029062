// 查询form
export const dataForm = () => {
  return [
    {
      label: '角色',
      prop: 'search',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      }
    }
  ]
}
// 表格form
export const dataTable = (context) => {
  return [
    {
      label: '所属机构',
      prop: 'orgType',
      width: '80px',
      formatter: row => context.userTypeMap[row.orgType]
    },
    {
      label: '角色名',
      prop: 'roleName'
    },
    {
      label: '角色编码',
      prop: 'roleCode'
    },
    {
      label: '角色描述',
      prop: 'roleDesc'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
